import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";

const LetterByLetterTransition = ({ text, isMobile }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayText(text.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100); // Adjust the interval for the transition speed (in milliseconds)

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [text]);

  return (
    <div>
      <Grid style={{fontWeight:'700',fontSize:isMobile?'1rem':'3.5rem',color:'white',margin:'0.5rem'}}>{displayText}</Grid>
    </div>
  );
};

export default LetterByLetterTransition;
